/* questionDetail.css */
.question-detail-container {
    width: 70%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid #ece3e3;
    border-bottom: 0;
  }
  
  .question-detail {
    font-size: 18px;
    line-height: 1.5;
  }
  

  /* questionList.css */
.question-list-container {
  width: 30%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.question-detail {
  font-size: 18px;
  line-height: 1.5;
}


/* questionList.css */
.question-list-container {
width: 30%;
padding: 20px;
/* background-color: #f5f5f5; */
border: 0.5px solid #ece3e3;
border-bottom: 0;
color: #333;
transition: background-color 0.3s ease;
}

.question-list-item {
cursor: pointer;
padding: 10px;
border-bottom: 1px solid #eee;
margin-right: 1px;
transition: background-color 0.3s ease;
}

.question-list-item:hover {
background-color: rgb(246, 107, 107);
border: 0.5px solid rgb(246, 107, 107);
color: #fff;
}

.marked{
  background-color: #2e7d32;
  border: 0.5px solid #2e7d32;
  color: #fff;
  border-radius: 5px;
}

.css-u4tvz2-MuiFormLabel-root.Mui-focused{
  color: rgba(0, 0, 0, 0.6) !important;
  }

  .MuiFormGroup-root{
    margin-left: 20px;
  }

  .prevNclearButton>*{
    margin: 0 0 0 10px !important;
  }

  .question-list-item.active{
    border: 1px solid rgb(216, 212, 212);
    border-radius: 5px;
  }
