.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1536px){

.css-89bgxa-MuiContainer-root {
    max-width: unset !important;
}}

@media (min-width: 0px){
.css-nzinn5-MuiContainer-root {
    max-width: unset !important;
}}

.LoginDiv{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  width: 25%;
  margin: 5% auto;
  padding: 1% 5% 4% 5%;
}

.copyright{
  /* margin-top:  !important; */
  color:#9e9e9e;
  text-align: center;
  margin-top: 12%;

  /* color: blue; */
}

.timer{
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer h2{
  margin: 0 !important;
}

.output-box{
  width: 97% !important;
}

