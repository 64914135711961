/* questionDetail.css */
.question-detail-container1 {
    width: 70%;
    padding: 20px;
    height: 600px;
  }
  
  .question-detail {
    font-size: 18px;
    line-height: 1.5;
  }
  

  /* questionList.css */
.question-list-container {
  width: 30%;
  padding: 20px;
  border: 0.5px solid #ece3e3;
  border-bottom: 0;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.question-list-item {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.question-list-item:hover {
  background-color: rgb(246, 107, 107);
  border: 0.5px solid rgb(246, 107, 107);
  color: #fff;
}



.button-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .audio-controls,
  .video-controls {
      margin-bottom: 20px;
  }
  .audio-player,
  .video-player {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .audio-player,
  .video-player,
  .recorded-player {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .live-player {
      height: 400px;
      width: 100%;
      border: 1px solid #646cff;
      margin-bottom: 30px;
  }
  .recorded-player video {
      height: 400px;
      width: 100%;
  }


  .run-bttn{
    position: absolute;
    bottom: 0px;
    right: 18px;
    width: 80px;
    height: 40px;
    font-size: 22px;
    font-weight: bold;
    background-color: green;
    border: none;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    }
    .run-bttn:active{
    background-color: #6e9427;
    }