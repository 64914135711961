.App{
    max-height: 100vh;
    width: 100%;
    overflow-y: hidden;
    background-color: #474747;
    }
    .main{
    display: flex;
    height: calc(89vh - 50px);
    }
    .left-container{
    position: relative;
    flex: 60%;
    height: calc(89vh - 50px);
    }
    .right-container{
    flex: 40%;
    height: calc(89vh - 50px);
    display: flex;
    flex-direction: column;
    background-color: #242424;
    border-left: 3px solid #1f65e6;
    padding: 5px;
    }
    .input-box{
    flex: 55%;
    }
    .input-box textarea{
    font-size: 16px;
    }
    .spinner-box{
    flex: 50%;
    background-color: #242424;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .spinner-box img{
    width: 200px;
    }
    .output-box{
    flex: 50%;
    background-color: #242424;
    overflow-y: auto;
    color: white;
    position: relative;
    width: 340px;
    border: 5px solid black;
    }
    .clear-btn{
    position: absolute;
    bottom: 95px;
    right: 18px;
    width: 80px;
    height: 40px;
    font-size: 22px;
    font-weight: bold;
    color: white;
    background-color: #1f65e6;
    border: none;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    }
    .output-box pre{
    font-size: 15px;
    white-space: pre-wrap;
    }
    h4{
    color: #afec3f;
    }
    #code-inp{
    box-sizing: border-box;
    width: 100%;
    height: 70%;
    resize: none;
    background-color: #242424;
    color: whitesmoke;
    padding: 5px;
    }
    #code-inp:focus{
    outline: none;
    }
    .run-btn{
    position: absolute;
    bottom: 10px;
    right: 18px;
    width: 80px;
    height: 40px;
    font-size: 22px;
    font-weight: bold;
    background-color: #afec3f;
    border: none;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    }
    .run-btn:active{
    background-color: #6e9427;
    }
    